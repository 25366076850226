@import '~bootstrap/dist/css/bootstrap.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root {
  --primaryColor: #D11E4A;
  ;
  --BackgroundColor: #ffffff;
  --containerColor: #ffffff;
  --blackFont: #000000;
  --whiteFont: #ffffff;
  --greyFont: #727272;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', 'Quicksand' !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--BackgroundColor);
}

.font-red-25-bold {
  font-size: 25px;
  color: var(--primaryColor);
  font-weight: bold;
}

.font-red-32-bold {
  font-size: 32px;
  color: var(--primaryColor);
  font-weight: bold;
}

.font-red-13-bold {
  font-size: 13px;
  color: var(--primaryColor);
  font-weight: bold;
}

.font-red-24-bold {
  font-size: 24px;
  color: var(--primaryColor);
  font-weight: bold;
}

.font-red-40-bold {
  font-size: 40px;
  color: var(--primaryColor);
  font-weight: bold;
}

.font-silver-52-bold {
  font-size: 52px;
  color: #E7E7E7;
  font-weight: bold;
}

.font-black-normal {
  font-size: var(--s);
  color: var(--blackFont);
}

.font-black-10-normal {
  font-size: 10px;
  color: var(--blackFont);
}

.font-black-11-normal {
  font-size: 11px;
  color: var(--blackFont);
}

.font-black-11-thin {
  font-size: 11px;
  color: var(--blackFont);
}

.font-black-11-bold {
  font-size: 11px;
  color: var(--blackFont);
  font-weight: bold;
}

/* 12px black fonts */
.font-black-12-normal {
  font-size: 12px;
  color: var(--blackFont);
}

.font-black-12-thin {
  font-size: 12px;
  color: var(--blackFont);
}

.font-black-12-bold {
  font-size: 12px;
  color: var(--blackFont);
  font-weight: bold;
}

/* 14px black fonts */

.font-black-14-normal {
  font-size: 14px;
  color: var(--blackFont);
}

.font-black-14-thin {
  font-size: 14px;
  color: var(--blackFont);
}

.font-black-14-bold {
  font-size: 14px;
  color: var(--blackFont);
  font-weight: bold;
}

/* 16px black fonts */

.font-black-16-normal {
  font-size: 16px;
  color: var(--blackFont);
}

.font-black-16-thin {
  font-size: 16px;
  color: var(--blackFont);
}

.font-black-16-bold {
  font-size: 16px;
  color: var(--blackFont);
  font-weight: bold;
}

/* 18px black fonts */

.font-black-18-normal {
  font-size: 18px;
  color: var(--blackFont);
}

.font-black-18-thin {
  font-size: 18px;
  color: var(--blackFont);
}

.font-black-18-bold {
  font-size: 18px;
  color: var(--blackFont);
  font-weight: bold;
}

.font-black-40-bold {
  font-size: 40px;
  color: var(--blackFont);
  font-weight: bold;
}

/* 20px black fonts */
.font-black-20-bold {
  font-size: 20px;
  color: var(--blackFont);
  font-weight: bold;
}

.font-black-20-normal {
  font-size: 20px;
  color: var(--blackFont);
  font-weight: 500;
}

/* 24px black fonts */
.font-black-24-bold {
  font-size: 24px;
  color: var(--blackFont);
  font-weight: bold;
}

.font-black-24-thin {
  font-size: 24px;
  color: var(--blackFont);
  font-weight: 400;
}

.font-black-22-thin {
  font-size: 22px;
  color: var(--blackFont);
  font-weight: 400;
}

/* 26px black fonts */
.font-black-26-bold {
  font-size: 26px;
  color: var(--blackFont);
  font-weight: bold;
}

.font-black-28-bold {
  font-size: 26px;
  color: var(--blackFont);
  font-weight: bold;
}

.font-black-28-normal {
  font-size: 28px;
  color: var(--blackFont);
  font-weight: normal;
}

.font-black-30-normal {
  font-size: 30px;
  color: var(--blackFont);
  font-weight: normal !important;
}

/* 32px black fonts */
.font-black-32-bold {
  font-size: 32px;
  color: var(--blackFont);
  font-weight: bold;
}

.font-black-32-normal {
  font-size: 32px;
  color: var(--blackFont);
  font-weight: normal;
}

/* 36px black fonts */
.font-black-36-bold {
  font-size: 36px;
  color: var(--blackFont);
  font-weight: bold;
}

/* 38px black fonts */
.font-black-38-normal {
  font-size: 38px;
  color: var(--blackFont);
  font-weight: bold;
}

.font-black-40-bold {
  font-size: 40px;
  color: var(--blackFont);
  font-weight: bold;
}

.font-black-42-normal {
  font-size: 42px;
  color: var(--blackFont);
  font-weight: bold;
}

.font-black-42-thin {
  font-size: 42px;
  color: var(--blackFont);
  font-weight: 400;
}

.font-black-40-normal {
  font-size: 35px;
  color: var(--blackFont);
  font-weight: 500;
}

@media (min-width:600px) and (max-width:1100px) {
  .font-black-40-normal {
    font-size: 24px;
  }
}

.font-black-30-normal {
  font-size: 30px;
  color: var(--blackFont);
  font-weight: 300;
}

.font-black-30-bold {
  font-size: 30px;
  color: var(--blackFont);
  font-weight: 900;
}

.font-black-32-normal {
  font-size: 3px;
  color: var(--blackFont);
  font-weight: 300;
}


.font-black-120-normal {
  font-size: 120px;
  color: var(--blackFont);
  font-weight: 900;
}

.font-black-75-normal {
  font-size: 75px;
  color: var(--blackFont);
  font-weight: 900;
}


/* font grey */
.font-grey-10-normal {
  font-size: 10px;
  color: var(--blackFont);
  opacity: 0.7;
}

.font-grey-10-thin {
  font-size: 10px;
  color: var(--blackFont);
  opacity: 0.7;
}

.font-grey-10-bold {
  font-size: 10px;
  color: var(--blackFont);
  opacity: 0.7;
  font-weight: bold;
}

.font-grey-11-normal {
  font-size: 11px;
  color: var(--blackFont);
  opacity: 0.7;
}

.font-grey-11-thin {
  font-size: 11px;
  color: var(--blackFont);
  opacity: 0.7;
}

.font-grey-11-bold {
  font-size: 11px;
  color: var(--blackFont);
  opacity: 0.7;
  font-weight: bold;
}

/* 12px grey fonts */
.font-grey-12-normal {
  font-size: 12px;
  color: var(--blackFont);
  opacity: 0.7;
}

.font-grey-12-thin {
  font-size: 12px;
  color: var(--blackFont);
  opacity: 0.7;
}

.font-grey-12-bold {
  font-size: 12px;
  color: var(--blackFont);
  opacity: 0.7;
  font-weight: bold;
}

/* 14px grey fonts */

.font-grey-14-normal {
  font-size: 14px;
  color: var(--blackFont);
  opacity: 0.7;
}

.font-grey-14-thin {
  font-size: 14px;
  color: var(--blackFont);
  opacity: 0.7;
}

.font-grey-14-bold {
  font-size: 14px;
  color: var(--blackFont);
  opacity: 0.7;
  font-weight: bold;
}

/* 16px black fonts */

.font-grey-16-normal {
  font-size: 16px;
  color: var(--blackFont);
  opacity: 0.7;
}

.font-grey-16-thin {
  font-size: 16px;
  opacity: 0.7;
  color: var(--blackFont);
}

.font-grey-16-bold {
  font-size: 16px;
  color: var(--blackFont);
  opacity: 0.7;
  font-weight: bold;
}

/* 18px grey fonts */

.font-grey-18-normal {
  font-size: 18px;
  color: var(--blackFont);
  opacity: 0.7;
}

.font-grey-18-thin {
  font-size: 18px;
  color: var(--blackFont);
  opacity: 0.7;
}

.font-brown-14-bold {
  font-size: 14px;
  color: #332620;
}

.font-grey-18-bold {
  font-size: 18px;
  color: var(--blackFont);
  opacity: 0.5;
  font-weight: bold;
}

.font-green-20-bold {
  font-size: 20px;
  color: var(--greenFont);
  font-weight: bold;
}

.font-brown-20-bold {
  font-size: 20px;
  color: var(--BrownFont);
  font-weight: bold;
}

/* White font css */
.font-white-11-normal {
  font-size: 11px;
  color: var(--whiteFont);
}

.font-white-11-thin {
  font-size: 11px;
  color: var(--whiteFont);
}

.font-white-11-bold {
  font-size: 11px;
  color: var(--whiteFont);
  font-weight: bold;
}

/* 12px white fonts */
.font-white-12-normal {
  font-size: 12px;
  color: var(--whiteFont);
}

.font-white-12-thin {
  font-size: 12px;
  color: var(--whiteFont);
}

.font-white-12-bold {
  font-size: 12px;
  color: var(--whiteFont);
  font-weight: bold;
}

/* 14px white fonts */

.font-white-14-normal {
  font-size: 14px;
  color: var(--whiteFont);
}

.font-white-14-thin {
  font-size: 14px;
  color: var(--whiteFont);
}

.font-white-14-bold {
  font-size: 14px;
  color: var(--whiteFont);
  font-weight: bold;
}

/* 16px white fonts */

.font-white-16-normal {
  font-size: 16px;
  color: var(--whiteFont);
}

.font-white-16-thin {
  font-size: 16px;
  color: var(--whiteFont);
}

.font-white-16-bold {
  font-size: 16px;
  color: var(--whiteFont);
  font-weight: bold;
}

/* 18px white fonts */

.font-white-18-normal {
  font-size: 18px;
  color: var(--whiteFont);
}

.font-white-18-thin {
  font-size: 18px;
  color: var(--whiteFont);
}

.font-white-18-bold {
  font-size: 18px;
  color: var(--whiteFont);
  font-weight: bold;
}

/* other white fonts */
.font-white-150-bold {
  font-size: 150px;
  color: var(--whiteFont);
  font-weight: bold;

}

.font-white-48-normal {
  font-size: 48px;
  color: var(--whiteFont);
  font-weight: 300;
}

.font-white-24-normal {
  font-size: 24px;
  color: var(--whiteFont);
  font-weight: 400;
}

.font-white-24-bold {
  font-size: 24px;
  color: var(--whiteFont);
  font-weight: 900;
}


/* other white fonts */
.font-white-122-bold {
  font-size: 124px;
  color: var(--whiteFont);
  font-weight: bold;

}

.font-white-30-normal {
  font-size: 30px;
  color: var(--whiteFont);
  font-weight: 300;
}

.font-white-30-bold {
  font-size: 30px;
  color: var(--whiteFont);
  font-weight: 900;
}

.font-white-22-normal {
  font-size: 22px;
  color: var(--whiteFont);
  font-weight: 100;
}

.font-white-20-normal {
  font-size: 20px;
  color: var(--whiteFont);
  font-weight: 100;
}

.font-white-35-normal {
  font-size: 35px;
  color: var(--whiteFont);
  font-weight: 300;

}

.font-white-40-normal {
  font-size: 40px;
  color: var(--whiteFont);
  font-weight: 300;
  line-height: 45px;
}

.font-white-45-normal {
  font-size: 45px;
  color: var(--whiteFont);
  font-weight: 300;
  line-height: 45px;
}

.font-white-40-bold {
  font-size: 40px;
  color: var(--whiteFont);
  font-weight: 900;
}

.font-white-18-normal {
  font-size: 18px;
  color: var(--whiteFont);
  font-weight: 100;
}

.font-white-60-bold {
  font-size: 60px;
  color: var(--whiteFont);
  font-weight: 700;
}

.font-white-60-thin {
  font-size: 60px;
  color: #DFDFDF;
  font-weight: 700;
  opacity: 0.8;

}

.font-white-45-bold {
  font-size: 45px;
  color: var(--whiteFont);
  font-weight: 700;
}

.font-white-45-thin {
  font-size: 45px;
  color: #DFDFDF;
  font-weight: 700;
  opacity: 0.8;
}

/* primary color */
.font-primary-16-normal {
  font-size: 16px;
  color: var(--primaryColor);
  font-weight: 400;

}

.font-white-24-thin {
  font-size: 24px;
  color: var(--whiteFont);
  font-weight: 100;
  opacity: 0.5;
}

.font-white-52-bold {
  font-size: 52px;
  color: var(--whiteFont);
  font-weight: 700;
}

.font-primary-45-normal {
  font-size: 45px;
  color: var(--primaryColor);
  font-weight: 700;

}

.font-primary-13-bold {
  font-size: 13px;
  color: var(--primaryColor);
  font-weight: 700;
}

/* primary color */
.font-primary-14-normal {
  font-size: 14px;
  color: var(--primaryColor);
  font-weight: 400;
}

.font-primary-12-normal {
  font-size: 12px;
  color: var(--primaryColor);
  font-weight: 400;
}

.font-primary-16-bold {
  font-size: 14px;
  color: var(--primaryColor);
  font-weight: 600;
}

.font-primary-12-bold {
  font-size: 12px;
  color: var(--primaryColor);
  font-weight: 600;
}

.font-primary-60-bold {
  font-size: 60px;
  color: var(--primaryColor);
  font-weight: 700;
}

.font-primary-22-bold {
  font-size: 21px;
  color: var(--primaryColor);
  font-weight: 700;
}



.primary-button {
  border: 2px solid var(--primaryColor);
  border-radius: 100px;
  padding: 10px 20px;
  background-color: transparent;
  color: var(--primaryColor);
  font-weight: 900;
  width: fit-content;
}

.primary-button:hover {
  background-color: var(--primaryColor);
  color: var(--whiteFont);
}

@media only screen and (max-width: 600px) {
  .primary-button {
    padding: 10px 10x;
  }
}

.rotation {
  width: 900px;
  transform: rotate(270deg);
  cursor: pointer;
  position: relative;
  left: -25rem;
  margin-top: 16rem;
  overflow: none;
}


.squarebox {
  width: 6px;
  height: 6px;
  border: 1px solid #D11E4A;
  background-color: #D11E4A;
}

.squareboxNonClick {
  width: 6px;
  height: 6px;
  border: 1px solid #D11E4A;
  background-color: #ffffff;
}

.logo-align {
  margin-left: 20px;
}

.Title1M {
  font-weight: 700;
  font-size: 58px;
  color: #E7E7E7;
  line-height: 100px;
  text-shadow: 0px 2px;

}

.Title2M {
  font-weight: 900;
  font-size: 70px;
  color: #D11E4A;
  line-height: 50px;
  font-family: 'Quicksand' !important;
  text-shadow: 0px 2px;

}

.Title1 {
  font-weight: 700;
  font-size: 120px;
  color: #E7E7E7;
  text-shadow: 0px 2px, 2px 0px, 2px 0px;
  white-space: nowrap;

}

@media (min-width:600px) and (max-width:1100px) {
  .Title1 {
    font-size: 100px;
  }
}

.Title2 {
  color: #D11E4A;
  font-weight: 900;
  font-size: 180px;
  margin-top: -6%;
  font-family: 'Quicksand' !important;
  text-shadow: 0px 2px, 2px 0px, 2px 0px;
}

@media (min-width:600px) and (max-width:1100px) {
  .Title2 {
    font-size: 120px;
  }
}

.circle {
  width: 95%;
}

@media (min-width:600px) and (max-width:1100px) {
  .circle {
    width: 50%;
  }
}

@media (min-width:600px) and (max-width:1100px) {
  .font-black-30-normal {
    font-size: 24px;
  }
}


.Title3 {
  color: #D11E4A;
  font-weight: 700;
  font-size: 130px;
  line-height: 155px;
}

@media (min-width:600px) and (max-width:1100px) {
  .Title3 {
    font-size: 110px;
    white-space: nowrap;
  }
}

.Title4 {
  color: #E7E7E7;
  font-weight: 700;
  font-size: 160px;
  line-height: 254px;
  margin-top: -4%;
}

@media (min-width:600px) and (max-width:1100px) {
  .Title4 {
    font-size: 130px;
  }
}

.Learn {
  color: #000000;
  font-weight: 300;
  font-size: 96.0px;
  line-height: 85px;
}

@media (min-width:600px) and (max-width:1100px) {
  .Learn {
    font-size: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .Learn {
    font-size: 24px;
    font-weight: 300;
  }
}

.Build {
  color: #000000;
  font-weight: 600;
  font-size: 96.0px;
  line-height: 85px;
}

@media only screen and (max-width: 600px) {
  .Build {
    font-size: 24px;
    font-weight: 500;
  }
}

@media (min-width:600px) and (max-width:1100px) {
  .Build {
    font-size: 60px;
  }
}

.Evolve {
  color: #000000;
  font-weight: 900;
  font-size: 96.0px;
  line-height: 85px;
}

@media only screen and (max-width: 600px) {
  .Evolve {
    font-size: 24px;
    font-weight: bolder;
  }
}

@media (min-width:600px) and (max-width:1100px) {
  .Evolve {
    font-size: 60px;
  }
}

.Hline {
  border: 2px solid #000000;
  width: 50px;
  height: 0px;
}


/* contact form input */
.custom-input {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 57px;
  color: white;
  border-color: transparent;
}

.custom-input::placeholder {
  color: var(--whiteFont);
  font-weight: 400;
}

.custom-input:focus {
  border: 2px solid #D11E4A;
  background-color: #65556e;
  color: white;
}


.corevalues {
  color: rgba(255, 255, 255, 0.3);
  /* font-size: 170px; */
  font-weight: 900;
}

.corevaluesmob {
  color: rgba(255, 255, 255, 0.3);
  /* font-size: 90px; */
  font-weight: 900;
}

/* careers */
.careers {
  color: #E7E7E7;
  font-weight: 800;
  font-size: 120px;
}

.careersmob {
  color: #E7E7E7;
  font-weight: 800;
  font-size: 50px;
}

.contents {
  max-width: 1235px;
}

.lh-50 {
  line-height: 50px;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border-right: none !important;
}

.NavBar-contents {
  position: fixed;
  bottom: 8%;
  left: -1%
}

.VDivider {
  border: 1px solid #000000;
  transform: rotate(270deg);
  width: 75px;
}

.BDivivder {
  margin-left: 4rem;
  margin-top: 4rem;
}

.SubText {
  margin-top: 3rem;
  margin-left: 4rem;
  width: 75%;
}

.content {
  margin-top: 3rem;
  font-size: 20px;
}

a {
  text-decoration: none;
}


.caro-Color {
  background-color: #D11E4A !important;
  height: 10px !important;
  width: 10px !important;
  border-radius: 50%;
  transform: translateY(55px);
}

.Testimonial-section {
  background-image: url('./Assests/coverBg.png');
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 50px 100px;
}

.vedio-title {
  background-color: #000000;
  opacity: 0.3;
  width: 100%;
  height: 100px;
  z-index: 9999999999999;
}

.caro-button-container {}

.caro-button {
  background-color: #000000;
  color: #ffffff;
  border-radius: 50%;
  border: none;
  width: 20px;
  height: 20px;
}

.caro-img {
  width: 150px !important;
  float: left;
}

.caro-MainImg {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.control-dots {
  display: none !important;
}

.stepper {
  transform: translateY(-35px) translateX(1030px);
}

.testimonalcard-mobile {
  height: 560px;
  background: #FFFFFF;
  box-shadow: 0px 5px 30px rgba(179, 31, 36, 0.05);
  border-radius: 5px;
}

.Testimonial-sectionmob {
  background-image: url('./Assests/coverBg.png');
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 25px;
}

.custom-carousel .slick-dots li button::after {
  color: red;
}

.custom-carousel .slick-slide {
  padding: 15px 10px;
}
.custom-carousel1 .slick-slide{
  padding: 0px 10px 0px 10px;
}

.caro-button-container {
  top: 300px;
  right: 10%;
}

@media only screen and (max-width: 1210px) {
  .caro-button-container {
    bottom: -350px
  }
}
.Testimonial-card {
  background: #FFFFFF;
  box-shadow: 0px 5px 30px rgba(179, 31, 36, 0.05);
  border-radius: 8px;
}